.App {
  text-align: center;
}

.bold {
  font-weight: bold !important;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration-line: underline;
}

.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}